import request from '@/utils/request';
//查询用户信息
export function getCurrentUser(data) {
    return request({
        url: '/rbac/user/getCurrentUser',//请求路径
        method: 'get',
        params:data
    });
}
//编辑用户信息
export function modifyUserAndRole(data) {
    return request({
        url: '/rbac/user/modifyUserAndRole',//请求路径
        method: 'post',
        data
    });
}
//分页查询企业列表
export function listPageEnterprise(data) {
    return request({
        url: '/enterprise/listPageEnterprise',//请求路径
        method: 'get',
        params:data
    });
}