<template>
    <div class="add-infor">
        <van-form class="form-container" @submit="onSubmit">
            <div class="formBox">
                <van-field
                    v-model="userInfo.realName"
                    name="realName"
                    label="用户姓名"
                    required
                    placeholder="请输入姓名"
                    :rules="[{ required: true}]"
                />
                <!-- <van-field
                    v-model="userInfo.userName"
                    name="userName"
                    label="用户账号"
                    placeholder="请输入用户账号"
                /> -->
                <van-field
                    v-model="userInfo.phoneNum"
                    name="phoneNum"
                    label="联系电话"
                    placeholder="请正确手机号码"
                    
                />
                <!-- <van-field
                    v-model="userInfo.password"
                    name="password"
                    label="用户密码"
                    placeholder="请输入用户密码"
                /> -->
            </div>
            <div class="formBox">
                <van-field
                    v-model="userInfo.desc"
                    name="desc"
                    label="所属企业"
                    readonly
                    placeholder="请选择所属企业"
                    @click="showSelectEnterprise"
                />
                <!-- <van-field
                    v-model="userInfo.orgName"
                    name="orgName"
                    label="所属园区"
                    readonly
                    placeholder="请选择所属园区"
                    @click="showSelectPark"
                /> -->
                <van-field
                    v-model="userInfo.orgId"
                    name="orgId"
                    label="园区码"
                    placeholder="请输入园区码"
                    
                />
            </div>
            <div class="form-bottom">
                <van-button round block type="info" native-type="submit" >保存</van-button>
            </div>
        </van-form>
        <!-- 企业选择 -->
        <van-action-sheet v-model="show" :actions="actions" @select="select" />
        <!-- 园区选择 -->
        <van-action-sheet v-model="show2" :actions="actions2" @select="select2" />
    </div>
</template>

<script>
import {listTreeOrg } from '@/api/plotManagement';
import { getCurrentUser,modifyUserAndRole,listPageEnterprise} from '@/api/personManagement';
import {getUserId,getToken,getUsername,getOrgId,getUserType } from '@/utils/cookie'
export default {
    data() {
        return {
            // isAdd: true,//判断是新增还是编辑
            userInfo: {
                realName: '', 
                // userName: '',
                phoneNum: '', 
                password: '', 
                desc: '', 
                orgName: '', 
                parkId:'', //园区id
                menuPid: '', // 父级地块ID
                orgId:'',
                roleIdList:'',
                type:''
            },
            // switchChecked: true,//是否启用
            show: false,//是否显示选择企业
            actions: [],
            show2: false,//是否显示选择园区
            actions2: [],
        }
    },
    mounted() {
        //根据useid查找 人员信息
        this.getCurrentUser();
        /** 查询所有企业 */
        this.listAllEnterprise();
        /** 查询所有园区 */
        this.listAllPark()
    },
    methods: {
        onSubmit(val) {
            console.log(this.userInfo)
            let newUserInfo = JSON.parse(JSON.stringify(this.userInfo));
            newUserInfo.userId =   getUserId ();
            newUserInfo.roleIdList =   newUserInfo.type == 1  ?  [54] : [56];
            console.log(newUserInfo)
            //密码加密
            // newUserInfo.password =this.RSA.getRsaCode(newUserInfo.password);
            modifyUserAndRole(newUserInfo).then(res => {
                if (res.code == 200) {
                     this.$notify({type: 'success', message: '编辑成功'});
                    // this.$router.push({path: '/plotManagement'})
                }
            });
        },
        onCancel() {

        },
        select(val) {
            this.userInfo.desc = val.name;
            // this.userInfo.parkId = val.orgId;
            // this.menuPid = val.orgPid;
            this.show = false;
        },
        select2(val) {
            this.userInfo.orgName = val.name;
            this.userInfo.parkId = val.orgId;
            this.menuPid = val.orgPid;
            this.show2 = false;
        },
        /** 查询所有企业 */
        listAllEnterprise() {
            listPageEnterprise({
                // orgId: getOrgId(),
            }).then( res => {
                if( res.code === 200 ) {
                    res.data.forEach((item, index) => {
                        if (item) {
                            item.name = item.enterpriseName;
                        }
                    });
                    this.actions = res.data || [];
                }
            });
        },
        /** 查询所有园区 */
        listAllPark() {
            listTreeOrg({
                orgId: getOrgId(),
            }).then( res => {
                if( res.code === 200 ) {
                    res.data.forEach((item, index) => {
                        if (item) {
                            item.name = item.orgName;
                        }
                    });
                    this.actions2 = res.data || [];
                }
            });
        },
        //查询当前人员信息
        getCurrentUser(){
            getCurrentUser({
                // userNameLike: getUsername(),
                userId:getUserId()
            }).then( res => {
                if( res.code === 200) {
                    this.userInfo = Object.assign({},res.data);
                }
            })
        },
        /** 显示选择企业 */
        showSelectEnterprise(){
            this.show = true;
        },
        /** 显示选择园区 */
        showSelectPark() {
            this.show2 = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .add-infor {
        margin: 15px;
        height: 100%;
        // background-color: #fff;
        .form-container {
            // padding: 8px;
            .formBox{
                margin-bottom: 15px;
                border-radius: 4px;
                overflow: hidden;
            }
            .form-bottom {
                position: fixed;
                left: 12px;
                // bottom: 10px;
                bottom: 77px;
                width: 346px;
            }
        }
    }
</style>
<style lang="scss">
    .add-infor {
        .van-cell{
            padding: 14px 20px;
            font-size: 16px;
            .van-field__label{
                color:#4F4F4F;
                font-weight: bold;
            }
        }
        .van-popup {
            width: 100%;
            height: 100%;
            .map-container {
                width: 100%;
                height: 600px;
                margin: 0 auto;
                border-radius: 8px;
                .map {
                    width: 100%;
                    height: 100%;
                    // margin-top: 30px;
                    border-radius: 8px;
                }
            }
            .van-popup__close-icon {
                top: 5px;
                right: 5px;
                color: #a7a9af;
            }
            .map-search {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 346px;
            }
            .van-search {
                background-color: transparent;
            }
        }
        .btn-save {
            position: fixed;
            bottom: 10px;
            left: 12px;
            width: 346px;
        }
    }
    .tangram-suggestion-main {
        left: 25px !important;
        width: 310px !important;
        z-index: 999999;
    }
</style>